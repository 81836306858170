import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, firstValueFrom, Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {Permissions} from "@app/core/constants";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private _http = inject(HttpClient);
  private _permissions$ = new BehaviorSubject<string[]>([]);

  loadUserPermissions() {
    firstValueFrom(this._loadUserPermissions()).then();
  }

  _loadUserPermissions(): Observable<string[]> {
    return of({
      permissions: [
        Permissions.SuperAdmin,
        Permissions.UserCustomerManagement,
        Permissions.UserExpertManagement,
        Permissions.VoucherProviderManagement,
        Permissions.VoucherBuyListManagement,
        Permissions.VoucherInventorManagement,
        Permissions.ExchangeRate,
        Permissions.UserBankAccount,
        Permissions.Banks,
        Permissions.KycProvider,
        Permissions.CustomerKyc,
      ].flat()
    }).pipe(
      map(response => response.permissions),
      tap(response => {
        this._permissions$.next(response);
      }),
      catchError(() => {
        this._permissions$.next([]);
        return [];
      })
    )
    return this._http.get<{ permissions: string[] }>('/api/permissions').pipe(
      tap(response => {
        this._permissions$.next(response.permissions);
      }),
      map(response => response.permissions),
      catchError(() => {
        this._permissions$.next([]);
        return [];
      })
    );
  }

  userHasPermission(permissionKey: string | string[] | undefined, permissionType?: 'AND' | 'OR'): Observable<boolean> {
    if (!this._permissions$.getValue()?.length) this.loadUserPermissions();
    if (permissionKey === undefined) return of(true);
    return this.checkPermissions(permissionKey, permissionType);
  }

  private checkPermissions(permissionKey: string | string[], permissionType?: 'AND' | 'OR'): Observable<boolean> {
    return this._permissions$.pipe(
      map(permissions => {
        if (Array.isArray(permissionKey)) {
          if (permissionType === 'AND') return permissionKey.every(key => permissions.includes(key));
          if (permissionType === 'OR') return permissionKey.some(key => permissions.includes(key));
        } else {
          return permissions.includes(permissionKey);
        }
        return true;
      })
    );
  }
}
